export const { REACT_APP_API_BASE: API_BASE } = process.env;

//Logout api
export const API_LOGOUT = `${API_BASE}api/admin/logout`;

//Serviceable Area Module
export const API_GET_AREA_LIST = `${API_BASE}api/admin/getAreaList`;

export const API_GET_ACITVE_AREA_LIST = `${API_BASE}api/admin/getActiveAreaList`;

export const API_GET_AREA = `${API_BASE}api/admin/getArea`;

export const API_ADD_AREA = `${API_BASE}api/admin/addArea`;

export const API_UPT_AREA = `${API_BASE}api/admin/editArea`;

export const API_DEL_AREA = `${API_BASE}api/admin/deleteArea`;

//Vehicle  Module
export const API_GET_VEHICLE_LIST = `${API_BASE}api/admin/getVehicleList`;

export const API_GET_VEHICLE = `${API_BASE}api/admin/getVehicle`;

export const API_ADD_VEHICLE = `${API_BASE}api/admin/addVehicle`;

export const API_UPT_VEHICLE = `${API_BASE}api/admin/editVehicle`;

export const API_DEL_VEHICLE = `${API_BASE}api/admin/deleteVehicle`;

export const API_GET_ACTIVE_DRIVER = `${API_BASE}api/admin/listActiveUnAssignedDriver`;

export const API_ASSIGN_DRIVER = `${API_BASE}api/admin/assignDriver`;

export const API_ASSIGN_PRODUCT_LIST = `${API_BASE}api/admin/getListOfAssignProductToVehicle`;

export const API_ADD_PRODUCT_VEHICLE = `${API_BASE}api/admin/addProductToVehicle`;

export const API_EDIT_PRODUCT_VEHICLE = `${API_BASE}api/admin/editProductOfVehicle`;

export const API_ADD_PRODUCT_QUANTITY_TO_VEHICLE = `${API_BASE}api/admin/addProductQuantityToVehicle`;

export const API_REMOVE_PRODUCT = `${API_BASE}api/admin/removeProductFromVehicle`;

export const API_GET_PRODUCT_SKU = `${API_BASE}api/admin/getListOfProductSku`;

export const API_GET_VEHICLE_REPORT = `${API_BASE}api/admin/getVehicleReport`;


//Product Module
export const API_GET_PRODUCT_LIST = `${API_BASE}api/admin/listProduct`;

export const API_SHOW_PRODUCT_LIST = `${API_BASE}api/admin/showProductList`;

export const API_GET_PRODUCT = `${API_BASE}api/admin/getProductDetails`;
export const API_GET_PRODUCT_LIST_INFO = `${API_BASE}api/admin/getProductListDetails`;

export const API_ADD_PRODUCT = `${API_BASE}api/admin/addProduct`;

export const API_UPT_PRODUCT = `${API_BASE}api/admin/updateProduct`;

export const API_DEL_PRODUCT = `${API_BASE}api/admin/deleteProduct`;

export const API_GET_PRODUCT_VEHICLE_LIST = `${API_BASE}api/admin/getVehicleHavingProduct`;

export const API_GET_PRODUCT_STOCK_LIST = `${API_BASE}api/admin/getStockMovement`;

export const API_GET_VEHICLE_WITH_QTY = `${API_BASE}api/admin/getAllVehicleWithQuantity`;

//Driver  Module
export const API_GET_DRIVER_LIST = `${API_BASE}api/admin/listDriver`;

export const API_GET_DRIVER_LIST_W_VEHICLE = `${API_BASE}api/admin/listActiveAssignedDriver`;

export const API_GET_DRIVER = `${API_BASE}api/admin/getDriverDetails`;

export const API_ADD_DRIVER = `${API_BASE}api/admin/addDriver`;

export const API_AVAILBLE_VEHICLE_LIST = `${API_BASE}api/admin/listAvailableVehicles`;

export const API_UPT_DRIVER = `${API_BASE}api/admin/updateDriverDetails`;

export const API_DEL_DRIVER = `${API_BASE}api/admin/deleteDriver`;

export const API_DRIVER_STATUS_TOGGLE = `${API_BASE}api/admin/changeStatusDriver`;

export const API_RELEASE_VEHICLE = `${API_BASE}api/admin/releaseVehicle`;

export const API_GET_STATES = `${API_BASE}getStates`;

export const API_GET_CITY = `${API_BASE}/getCity`;

export const API_GET_CITIES = `${API_BASE}/getCities`;

//Promo code   Module
export const API_GET_PROMO_CODES_LIST = `${API_BASE}api/admin/listAllCoupon`;

export const API_ALL_CATEGORY_TYPES = `${API_BASE}api/admin/listAllCategoryType`;

export const API_ALL_PRODUCT_TYPES = `${API_BASE}api/admin/getListProductTypeByCategory`;

export const API_ALL_PRODUCTS = `${API_BASE}api/admin/getListProductByCategory`;

export const API_GET_PROMO_CODE_INFO = `${API_BASE}api/admin/getCoupon`;

export const API_ADD_PROMO_CODE = `${API_BASE}api/admin/addCoupon`;

export const API_CHANGE_PROMO_CODE_STATUS = `${API_BASE}api/admin/changeStatusCoupon`;

export const API_UPT_PROMO_CODE = `${API_BASE}api/admin/updateCoupon`;

export const API_DEL_PROMO_CODE = `${API_BASE}api/admin/deleteCoupon`;

export const API_SEND_NOTIFICATION_PROMO_CODE = `${API_BASE}api/admin/sendPromocode`;

export const API_APPLY_PROMOCDE = `${API_BASE}api/admin/adminApplyCoupon`;

//Remove Prmocode
export const API_REMOVE_PROMOCDE = `${API_BASE}api/customer/removeAppliedCoupon`

export const API_GET_PROMO_CODE_USED=`${API_BASE}api/admin/getAdminCouponUsed`;

//FAQ Module
export const API_GET_FAQ_LIST = `${API_BASE}api/admin/allFaq`;

export const API_GET_FAQ = `${API_BASE}api/admin/getFaqByCategory`;

export const API_GET_FAQ_CATEGORY = `${API_BASE}api/admin/allFaqCategory`;

export const API_ADD_FAQ = `${API_BASE}api/admin/addFaq`;

export const API_UPT_FAQ = `${API_BASE}api/admin/editFaq`;

export const API_DEL_FAQ = `${API_BASE}api/admin/deleteFaq`;

//Feedback Module
export const API_GET_FEEDBACK_LIST = `${API_BASE}api/admin/allFeedback`;

export const API_GET_FEEDBACK = `${API_BASE}api/admin/getFeedback`;

export const API_UPT_FEEDBACK_STATUS = `${API_BASE}api/admin/editFeedback`;

//Sub-Admin Module
export const API_GET_SUB_ADMIN_LIST = `${API_BASE}api/admin/listAllSubAdmin`;

export const API_GET_SUB_ADMIN = `${API_BASE}api/admin/getSubAdmin`;

export const API_ADD_SUB_ADMIN = `${API_BASE}api/admin/addSubAdmin`;

export const API_UPT_SUB_ADMIN = `${API_BASE}api/admin/editSubAdmin`;

export const API_DEL_SUB_ADMIN = `${API_BASE}api/admin/deleteSubAdmin`;

//Customer Module
export const API_GET_CUSTOMER_LIST = `${API_BASE}api/admin/listCustomer`;

export const API_GET_CUSTOMER_LIST_WITHOUT_ORDER = `${API_BASE}api/admin/listCustomerForReferral`;

export const API_ADD_NEW_CUSTOMER = `${API_BASE}api/admin/registerAdmin`;

export const API_ADD_USER_ADDRESS = `${API_BASE}api/admin/addCustomerAddress`;

export const API_UPDATE_USER_ADDRESS = `${API_BASE}api/admin/updateCustomerAddress`;

export const API_DELETE_USER_ADDRESS = `${API_BASE}api/customer/deleteAddress`;

export const API_GET_ALL_ADDRESS = `${API_BASE}api/admin/listAddress`;

export const API_SELECT_USER_ADDRESS_DEFAULT = `${API_BASE}api/admin/setDefaultAddress`;

export const API_GET_CUSTOMER_INFO = `${API_BASE}api/admin/getCustomer`;

export const API_GET_CUSTOMER_ORDER_LIST = `${API_BASE}api/admin/getOrderListOfCustomer`;

//Order Module
export const API_GET_ORDER_LIST = `${API_BASE}api/admin/getOrderList`;

export const API_GET_ORDER_INFO = `${API_BASE}api/admin/getOrderDetail`;

export const API_GET_ORDER_LOGS = `${API_BASE}api/admin/getOrderLog`;

export const API_SEND_PAYMENT_LINK = `${API_BASE}api/admin/sendPaymentLink`;

export const API_GET_RETURN_ORDER_LIST = `${API_BASE}api/admin/getReturnOrderList`;

export const API_ASSIGN_DRIVER_TO_ORDER = `${API_BASE}api/admin/updateOrderDriver`;

export const API_APRROVE_REJECT_RETURN_ORDER = `${API_BASE}api/admin/approveReturn`;

export const API_UPDATE_ORDER_STATUS = `${API_BASE}api/admin/updateOrderStatus`;

export const API_ADD_ADMIN_INSTRUCTION = `${API_BASE}api/admin/addAdminInstruction`;

//Notification Module
export const API_NOTIFICATION_COUNT = `${API_BASE}api/admin/unreadNotification`;

export const API_NOTIFICATION_LIST = `${API_BASE}api/admin/getNotificationList`;

export const API_READ_NOTIFICATION = `${API_BASE}api/admin/readNotification`;

export const API_DELETE_NOTIFICATION = `${API_BASE}api/admin/deleteNotification`;

// Find Distance Between Two Location
export const API_GET_LOCATION_BTWN_TWO = `https://maps.googleapis.com/maps/api/directions/json`;

// Dashboard AND REPORTS
export const API_GET_DASHBOARD_INFO = `${API_BASE}api/admin/dashboard`;

export const API_GET_REPORT_ORDER_LIST = `${API_BASE}api/admin/getOrderListByStatus`;

export const API_GET_COD_REPORT_ORDER_LIST = `${API_BASE}api/admin/getCodOrderReport`;

export const API_GET_REPORT_ORDER_LIST_BY_DRIVER = `${API_BASE}api/admin/getOrderListByDriver`;

export const API_GET_REPORT_ORDER_PINCODE_BY_DRIVER = `${API_BASE}api/admin/getOrderPincodeByDriver`;

export const API_GET_REPORT_COUNT_ORDER_BY_PINCODE = `${API_BASE}api/admin/getCountOfOrderByPincode`;

export const API_GET_COMPLETED_REPORT_ORDER_LIST = `${API_BASE}api/admin/getCompleteOrderListByStatus`;

export const API_GET_ORDER_PLACE_REPORT_LIST = `${API_BASE}api/admin/getOrderPlacedListByStatus`;

export const API_GET_REPORT_ORDER_LIST_BY_ORDER = `${API_BASE}api/admin/getOrderListByOrderStatus`;

export const API_GET_PRODUCT_REPORT_ORDER_LIST_BY_ORDER = `${API_BASE}api/admin/getOrderListForProductReport`;

export const API_GET_REPORT_CART_LIST = `${API_BASE}api/admin/getCartListByOrderDate`;

export const API_COHORT_REPORT = `${API_BASE}api/admin/getCohortReportByYear`;

export const API_GET_ORDER_PLACED_GRAPH = `${API_BASE}api/admin/getOrderStatByStatus`;

export const API_GET_ORDER_SNAP_SHOT_GRAPH = `${API_BASE}api/admin/getOrderRevenueSnapshotGraph`;

export const API_GET_ACTIVE_AND_INACTIVE_CUSTOMER = `${API_BASE}api/admin/activeAndInactiveCustomerReport`;

export const API_GET_ORDER_COUNT_BY_PINCODE_GRAPH = `${API_BASE}api/admin/getOrderCountByPincode`;

export const API_GET_COMPLETED_ORDER_GRAPH = `${API_BASE}api/admin/getCompletedOrderStatByStatus`;

export const API_GET_REPORT_DRIVER_LIST = `${API_BASE}api/admin/listDriverByStatus`;

export const API_GET_DRIVER_GRAPH = `${API_BASE}api/admin/getDriverStatByStatus`;

export const API_GET_RETURN_ORDER_GRAPH = `${API_BASE}api/admin/getReturnStatByStatus`;

export const API_GET_COD_ORDER_GRAPH = `${API_BASE}api/admin/getCodOrderStatByStatus`;

export const API_GET_VISITORS_GRAPH = `${API_BASE}api/admin/getVisitorsStatByStatus`;

export const API_GET_VISITORS_LIST = `${API_BASE}api/admin/getVisitorList`;

export const API_EXPORT_PDF = `${API_BASE}api/admin/getOrderListByStatusPDF`;

export const API_GET_APARTMENT_LIST = `${API_BASE}api/admin/getApartmentList`;

export const API_VISITORS_EXPORT_PDF = `${API_BASE}api/admin/getVisitorListPDF`;

export const API_DOWNLOAD_INVOICE = `${API_BASE}api/admin/genrateInvoice`

export const API_DOWNLOAD_INVOICE_ZIP = `${API_BASE}api/admin/getInvoiceZip`

export const API_SEND_CANCEL_ORDER = `${API_BASE}api/admin/cancelOrder`;

//Get Cart
export const API_GET_CART = `${API_BASE}api/customer/getCartItem`

//Referral Discount
export const API_GET_CUSTOMER_ORDER_COUNT=`${API_BASE}api/admin/getCustomerOrderCount`;
export const API_CHECK_AND_GET_REFERRAL=`${API_BASE}api/admin/checkAndGetReferral`;
export const API_CHECK_REFERRAL_CODE=`${API_BASE}api/admin/checkReferralCode`;


//Product Rating
export const API_GET_PRRODUCT_RATING_LIST = `${API_BASE}api/admin/listProductRating`;
export const API_PRODUCT_REVIEW_ACCEPT = `${API_BASE}api/admin/productReviewAccept`;
export const API_PRODUCT_REVIEW_REJECT = `${API_BASE}api/admin/productReviewReject`;
export const API_ADMIN_PRODUCT_REVIEW = `${API_BASE}api/admin/addAdminProductReview`;
